@use 'breakpoints' as breakpoints;
@use 'mixins' as mixins;

body.in-chat {
    padding-bottom: 0;
}

.chat-header {
    position: relative;
    @media (min-width: breakpoints.$screen-md-min) {
        background-color: var(--secondary-medium);
        border-radius: 4px;
        padding: 15px;
        height: auto;

        .avatar {
            width: 50px;
            height: 50px;
        }
    }

    .info {
        width: calc(100% - 300px);
        justify-content: unset;

        > div:first-child {
            width: 54px;

            @media (min-width: breakpoints.$screen-md-min) {
                width: 0;
            }
        }

        > div:last-child {
            width: 100%;
        }
    }

    .header-options {
        display: flex;
        align-items: center;
        background-color: var(--mp-main-dark);
        z-index: 1;
        padding-left: 10px;


        @media (min-width: breakpoints.$screen-md-min) {
            background-color: var(--secondary-medium);
        }

        @media (max-width: 450px) {
            mask-image: linear-gradient(to right, transparent 0, #000 6px, #000 100%);
        }

        &--with-mask {
            @media (max-width: 450px) {
                mask-image: linear-gradient(to right, transparent 0, #000 6px, #000 100%);
            }
        }

        &--no-mask {
            mask-image: none;
        }
    }

    .username {
        padding-left: 10px;
        @include mixins.truncate-singleline(calc(100% - 50px));

        .writing {
            font-size: 13px;
            font-weight: 400;
        }
    }

    a {
        cursor: pointer;
    }
}

.profile-header {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    background-color: transparent;
}

.bubble-list {
    @media (min-width: breakpoints.$screen-md-min) {
        height: 80px;
    }

    &__wrapper {
        display: flex;
        flex-direction: column-reverse;
        min-height: 100%;
        position: relative;
    }

    &__container {
        padding: 12px 15px;
        overflow-x: hidden;

        @media (min-width: breakpoints.$screen-md-min) {
            padding: 15px 20px 15px;
        }
    }

    &--padding {
        padding-top: 50px;
    }

    &--faker {
        padding-bottom: 60px;
        margin-bottom: 60px;
    }

    .total-padding {
        background-color: var(--currentConversation);

        @media (min-width: breakpoints.$screen-md-min) {
            background-color: transparent;
        }
    }

    &--items {
        width: 100%;
        display: block;
    }

    li {
        list-style: none;
        margin-bottom: 1px;

        .content .detail {
            font-size: 11px;
            line-height: 15px;
            height: 0;
            font-weight: 600;
            color: #777;
            overflow: hidden;
            transition: height .25s ease-out;

            .chat-new-msg-loader {
                position: relative;
                top: 2px;
                margin-left: auto !important;
            }
        }

        &.first-in-group {
            &.chatting-person .bubble {
                border-top-left-radius: 18px;
            }

            &.logged-user .bubble {
                border-top-right-radius: 18px;
            }
        }

        &.last-in-group {
            &.chatting-person .bubble {
                border-bottom-left-radius: 18px;
            }

            &.logged-user {
                .bubble {
                    border-bottom-right-radius: 18px;
                }
            }

            &.logged-user .content-inner .read-message-flag {
                display: none;
            }

            .detail {
                height: 15px;
                margin-bottom: 5px;
                display: block;
            }
        }
    }

    .content {
        .content-inner {
            max-width: 90%;
            display: flex;
        }

        &.show-detail {
            .content-inner .read-message-flag {
                display: none;
            }

            .detail {
                display: block;
                height: 15px;
                margin-bottom: 5px;
            }
        }
    }

    .bubble {
        min-width: 36px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        max-width: 100%;
        align-items: center;
        padding: 8px 12px;
        white-space: pre-wrap;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    li.chatting-person {
        &:not(.last-in-group) .content.show-detail .bubble {
            background: var(--secondary-light);
        }

        .bubble {
            background-color: #DDD;
            color: #222;
            border-radius: 4px 18px 18px 4px;

            &:hover {
                background-color: #D0D0D0;
            }

            &-wrapper {
                overflow: hidden;
            }

            &.writing {
                background: transparent;
                padding-left: 0;
                animation: writing-anim 0.75s;
            }
        }
    }

    li.logged-user {
        text-align: right;

        .content .content-inner {
            margin-left: auto;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            .read-message-flag {
                margin-bottom: 10px;
                margin-right: 5px;
            }
        }

        &:not(.last-in-group) .content.show-detail .bubble {
            background: #C00E00;
        }

        .bubble {
            background-color: var(--mp-red);
            color: var(--white);
            border-radius: 18px 4px 4px 18px;

            &:hover {
                background-color: var(--mp-red-hover);
            }
        }
    }
}

@keyframes writing-anim {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: none;
    }
}

footer.write-new-message {
    background-color: var(--secondary-medium);
    transform: translate3d(0, 0, 0);
    left: 0;
    z-index: 99;
    bottom: 0;
    padding: 12px 0;

    @media (max-width: breakpoints.$screen-md-min) {
        position: fixed;
    }

    &.profile-unavailable {
        background: var(--white);
        color: #707070;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 13px;
        line-height: 18px;
        padding-top: 0;
        border-top: 1px solid #DDD;

        div {
            padding: 0 10px;
            max-width: 370px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width: breakpoints.$screen-md-min) {
                max-width: 420px;
                height: 79px;
            }
        }

        @media (min-width: breakpoints.$screen-md-min) {
            font-size: 15px;
            line-height: 20px;
            padding-bottom: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .text-space {
        width: calc(100% - 40px);
        padding: 0 10px;
    }

    .button-space {
        width: 30px;
    }

    .row {
        display: flex;
    }

    .warning {
        color: var(--secondary-light);
        font-size: 9px;
        padding: 0 10px;
        align-items: center;
        height: 20px;

        div {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        @media (min-width: breakpoints.$screen-md-min) {
            justify-content: center;
            font-size: 11px;
        }
    }

    textarea {
        padding: 3px 10px;
        min-height: 30px;
        max-height: 114px;
        outline: none;
        border: none;
        @include mixins.border-radius(4px);
        vertical-align: top;
    }

    button {
        width: 30px;
        height: 30px;
        background-color: var(--mp-red);
        color: var(--white);
        @include mixins.border-radius(50%);
        margin: auto;
        border: none;
        text-align: center;
        line-height: 32px;

        &:hover {
            background-color: var(--mp-red-hover);
            color: var(--white);
        }

        &:focus {
            background-color: var(--mp-red);
            color: var(--white);
        }

        &:active {
            background-color: #000;
            color: var(--white);
        }

        i {
            font-size: 18px;
        }
    }
    @media (min-width: breakpoints.$screen-md-min) {
        padding: 15px 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 4px;
    }
}

.mp-message {
    height: 100%;
}

.chat-wrapper {
    height: 100%;

    @media (min-width: breakpoints.$screen-md-min) {
        margin-bottom: 0;
    }

    .scroll-to-bottom {
        position: fixed;
        bottom: calc(48px + env(safe-area-inset-bottom));
        left: 50%;
        transform: translateX(-50%);
        background: var(--white);
        color: var(--mp-red);
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        padding: 0;
        border: 0;
        border-radius: 999px;
        font-size: 15px;
        z-index: 3;
        display: flex;
        align-items: center;
        white-space: nowrap;
        cursor: pointer;
        margin-bottom: 60px;

        .material-icons {
            font-size: 22px;
            margin: 7px;
        }

        span {
            display: inline-block;
            line-height: 1;
            font-weight: 600;
            padding-right: 13px;
        }

        @media (min-width: breakpoints.$screen-md-min) {
            position: absolute;
            margin-bottom: 0;
            bottom: 15px;
        }
    }
}

@media (min-width: breakpoints.$screen-md-min) {
    .chat-flex {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;

        .placeholder-area {
            padding-bottom: 80px;
        }
    }
}

.chat-container {
    width: 100%;

    @media (min-width: breakpoints.$screen-md-min) {
        overflow-y: auto;
        max-height: 100%;
        height: 100%;
    }
}

.chat-header-wrapper {
    z-index: 100;
    @media (max-width: breakpoints.$screen-sm-max) {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 1000;
        padding-top: env(safe-area-inset-top);
    }
}

.chat-new-msg-loader {
    @include mixins.loader(1em);

    & {
      margin: 0 !important;
    }
}

.old-msg-loader {
    @include mixins.loader(34px);

    & {
      margin: 0 auto 10px !important;
    }
}

.chat-reload {
    top: 40vh;
}

.btn.btn-old-msgs {
    margin: 12px auto 12px;
    display: block;
    background-color: var(--secondary-light);
    color: var(--white);
}
